import { useEditData_v2 } from "@custom-hooks/edit-data";
import { AddonLookupKey } from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { Project } from "@data-types/projects-types";

/**
 * Custom hook for handling free project creation logic.
 *
 * @param onSuccess - Callback to execute upon successful project creation.
 * @param onError - Callback to execute upon error during project creation.
 *
 * @returns Hook state and actions for managing project creation.
 */
export function useCreateFreeProject(options: EditDataOpt<Project>) {
  const { isMutatingData, isLoading, editData } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const createFreeProject = async ({
    projectName,
    region,
    label,
  }: {
    projectName: string;
    region: string;
    label: number;
  }) => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/create`,
      endpointCallLocation: "useCreateFreeProject",
      body: {
        name: projectName,
        region,
        env: label,
        plan_id: AddonLookupKey.SANDBOX_PROJECT,
        description: "My awesome project",
      },
      mutateApis: [
        ["/api/projects", "useGetUserProjects"],
        ["/api/projects", "useGetProjects"],
        ["/api/auth/session", "useGetUserSession"],
      ],
    });
  };

  return {
    createFreeProject,
    isCreatingProject: isLoading || isMutatingData,
  };
}
