//
// billing-types.ts
//

import { Environment } from "@data-types/projects-types";
import { Region } from "@data-types/regions-types";
import { Stripe } from "stripe";

/**
 * Enum for addons functionalities.
 */
export enum AddonsFunctionality {
  CLUSTER = "Cluster",
  STORAGE_IN_GB = "Storage",
  VCPU = "vCPUs",
  RAM = "RAM",
  RETENTION_IN_HOURS = "Backups",
  SUPPORT = "Support",
  COLD_START = "Cold Start",
  EARLY_ACCESS = "Early Access",
  SLA = "SLA",
  REGIONS = "Regions",
  INSTANCE_SIZE = "Instance Size",
}

/**
 * Enum for addon lookup keys.
 * These keys represent additional features or services that can be added to a subscription.
 */
export enum AddonLookupKey {
  SANDBOX_PROJECT = "sandbox_project",
  PRO_PROJECT = "pro_project",
  SCALE_PROJECT = "scale_project",
}

/**
 * Enum representing the possible actions that can be performed on addons.
 *
 * - `CREATE`: Represents the creation of a new addon or resource.
 * - `UPGRADING`: Represents the process of upgrading an existing addon or resource.
 * - `DELETE`: Represents the removal of an existing addon or resource.
 * - `UNSUBSCRIBE`: Represents the cancellation or unsubscription of an addon or resource.
 *
 * ## Purpose
 * This enum defines the valid actions that can be associated with a specific addon type.
 * Each action corresponds to a unique operation, ensuring consistency when managing addon-related tasks.
 *
 * ## Usage
 * These action names are used in conjunction with addons to specify the type of operation
 * being performed. They help map operations to their respective handlers or data structures.
 */
export enum AddonActionNames {
  CREATE = "create",
  UPGRADE = "upgrade",
  DELETE = "delete",
  UNSUBSCRIBE = "unsubscribe",
}

/**
 * Defines the mapping of addon lookup keys to their respective actions and associated data structures.
 *
 * This type establishes the valid actions that can be performed for each addon type, along with
 * the corresponding data schema required for those actions. Each addon lookup key is associated
 * with an object where:
 * - The keys are action names (from `AddonActionNames`).
 * - The values define the expected structure of the data required for the action.
 *
 * ## Structure
 * ### Actions
 * - `CREATE`: Represents the creation of a new addon or resource.
 *   - `data`: Contains the necessary information for creating the addon:
 *     - `name`: The name of the resource.
 *     - `region`: The region ID where the resource will be created.
 *     - `env`: The environment (e.g., development, production).
 *     - `description`: (Optional) Additional details about the resource.
 *
 * - `UPGRADING`: Represents the upgrading of an existing resource.
 *   - `data`: Contains the unique identifier of the project to be upgraded:
 *     - `projectId`: The ID of the project.
 *
 * - `DELETE`: Represents the deletion of an existing addon or resource.
 *   - `data`: Contains the necessary information to identify the addon to delete:
 *     - `projectId`: The unique identifier of the project to be deleted.
 *
 * - `UNSUBSCRIBE`: Represents the unsubscription or cancellation of an addon.
 *   - `data`: Contains the unique identifier of the project to unsubscribe from:
 *     - `projectId`: The ID of the project.
 *
 * ## Example Usage
 * ```typescript
 * const action: AddonActionMap[AddonLookupKey.PRO_PROJECT][AddonActionNames.CREATE] = {
 *   name: AddonActionNames.CREATE,
 *   data: {
 *     name: "ProductionProject",
 *     region: "us-east-1",
 *     env: Environment.PRODUCTION,
 *     description: "A production-grade project",
 *   },
 * };
 * ```
 */
export type AddonActionMap = {
  [AddonLookupKey.PRO_PROJECT]: {
    [AddonActionNames.CREATE]: {
      name: AddonActionNames.CREATE;
      data: {
        name: string;
        region: Region["region_id"];
        env: Environment;
        description?: string;
      };
    };
    [AddonActionNames.UPGRADE]: {
      name: AddonActionNames.UPGRADE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.DELETE]: {
      name: AddonActionNames.DELETE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.UNSUBSCRIBE]: {
      name: AddonActionNames.UNSUBSCRIBE;
      data: {
        projectId: string;
      };
    };
  };
  [AddonLookupKey.SANDBOX_PROJECT]: {
    [AddonActionNames.CREATE]: {
      name: AddonActionNames.CREATE;
      data: {
        name: string;
        region: Region["region_id"];
        env: Environment;
        description?: string;
      };
    };
    [AddonActionNames.UPGRADE]: {
      name: AddonActionNames.UPGRADE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.DELETE]: {
      name: AddonActionNames.DELETE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.UNSUBSCRIBE]: {
      name: AddonActionNames.UNSUBSCRIBE;
      data: {
        projectId: string;
      };
    };
  };
  [AddonLookupKey.SCALE_PROJECT]: {
    [AddonActionNames.CREATE]: {
      name: AddonActionNames.CREATE;
      data: {
        name: string;
        region: Region["region_id"];
        env: Environment;
        description?: string;
      };
    };
    [AddonActionNames.UPGRADE]: {
      name: AddonActionNames.UPGRADE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.DELETE]: {
      name: AddonActionNames.DELETE;
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.UNSUBSCRIBE]: {
      name: AddonActionNames.UNSUBSCRIBE;
      data: {
        projectId: string;
      };
    };
  };
};

/**
 * Extracts the possible action types for a specific addon, including the action name.
 *
 * This type maps a specific addon lookup key to its associated actions. Each action
 * contains a `name` field indicating the type of action (e.g., "create", "delete")
 * and a `data` object containing the necessary parameters for the action.
 *
 * ## Example
 * For `AddonLookupKey.PRO_PROJECT`, the possible actions are:
 * - `CREATE`: Contains data such as `name`, `region`, `env`, and optional fields like `description`.
 * - `DELETE`: Contains data with a `projectId` identifying the project to be deleted.
 *
 * ## Usage
 * Use this type to enforce strict typing when dealing with actions for specific addons,
 * ensuring that each action includes both its `name` and the correct structure for its `data` object.
 */
export type AddonAction<K extends AddonLookupKey> =
  AddonActionMap[K][keyof AddonActionMap[K]];

/**
 * Represents an addon item with a dynamic action type based on the addon lookup key.
 *
 * An `AddonItem` encapsulates the data needed to describe an addon operation within
 * the subscription system. Each addon operation includes:
 * - A lookup key (`addon`) identifying the specific addon.
 * - A `quantity` indicating how many units of the addon are being requested (must not be zero).
 * - An `action` object detailing the operation to perform, where the action includes:
 *   - `name`: The type of operation (e.g., "create", "delete").
 *   - `data`: The parameters required for the operation, specific to the action name.
 *
 * ## Example
 * For an addon of type `PRO_PROJECT`:
 * ```typescript
 * const addonItem: AddonItem<AddonLookupKey.PRO_PROJECT> = {
 *   addon: AddonLookupKey.PRO_PROJECT,
 *   quantity: 1,
 *   action: {
 *     name: AddonActionNames.CREATE,
 *     data: {
 *       name: "Project Alpha",
 *       region: "us-east-1",
 *       env: 1,
 *       description: "First project setup",
 *     },
 *   },
 * };
 * ```
 *
 * ## Validations
 * - `quantity` must be a non-zero value. This indicates the number of units being added or removed.
 * - The `action` object must align with the addon key (`addon`) and include a valid action name
 *   and data structure as defined in the `AddonActionMap`.
 *
 * ## Purpose
 * This type ensures strong typing and clear semantics when managing addon operations, allowing for
 * precise integration between frontend logic and backend APIs.
 */
export type AddonItem<K extends AddonLookupKey = AddonLookupKey> = {
  addon: K;
  quantity: number; // Non-zero quantity validated at runtime
  action: AddonAction<K>; // Action tied strictly to the addon key
};

/**
 * Represents a subscription plan, its associated actions, default add-ons and UI details.
 *
 * - `lookupKey`: The unique identifier for the plan.
 * - `actions`: Defines actions to execute when the plan is activated.
 * - `defaultAddons`: Optional add-ons included by default, each with:
 *   - `addon`: The add-on identifier.
 *   - `quantity`: The number of add-ons included.
 *   - `action`: The actions associated with the add-on.
 * - `name` and `description`: Plan's display name and summary.
 * - `functionality`: Key features of the plan represented as key-value pairs,
 *      where the key is the functionality and the value is the numeric amount.
 * - `functionalityDetails`: Key features and their details, categorized by `AddonsFunctionality`.
 * - `additionalInfo`: Optional supplementary information.
 */
export type AddonsDetails<K extends AddonLookupKey = AddonLookupKey> = {
  lookupKey: K;
  name: string;
  description: string;
  additionalInfo?: string;
  includedFunctionality: Partial<Record<AddonsFunctionality, number>>; // TODO:DAMLA:TIZ - adapt to object received from stripe
  functionalityDetails: Partial<
    Record<
      AddonsFunctionality,
      { summary?: string; fullDescription: string; tooltip?: string }
    >
  >; // TODO:DAMLA:TIZ - adapt to object received from stripe
};

/**
 * Represents the base data structure for managing a subscription flow for an organization.
 *
 * - `email`: The email address of the user initiating the subscription process.
 * - `organizationId`: (Optional) The unique identifier of the organization associated with the subscription.
 * - `userId`: (Optional) The unique identifier of the user performing the action.
 * - `successUrl`: (Optional) The URL to redirect the user to after successfully completing the subscription process.
 * - `cancelUrl`: (Optional) The URL to redirect the user to if the subscription process is canceled or fails.
 * - `returnUrl`: (Optional) The URL to return to after completing the subscription flow.
 */
export interface HandlingSubscriptionFlow {
  email: string;
  organizationId?: string;
  userId?: string;
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
}

/**
 * Extends `HandlingSubscriptionFlow` for creating a subscription flow.
 *
 * This structure is used when creating a new subscription for an organization, requiring all
 * relevant details such as the organization ID, user ID, and plan details.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription creation process.
 * - `cancelUrl`: The URL to redirect the user to if the subscription creation process is canceled or fails.
 * - `plan`: The lookup key identifying the subscription plan to be created.
 * - `projectId`: The unique identifier of the project associated with the subscription.
 * - `returnUrl`: This field is not applicable in the creation flow and is excluded.
 */
export interface HandlingCreateSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl"> {
  successUrl: string;
  organizationId: string;
  userId: string;
  cancelUrl: string;
  newAddon: AddonItem;
  projectId: string;
}

/**
 * Extends `HandlingSubscriptionFlow` for updating a subscription flow.
 *
 * This structure is used when updating an existing subscription, requiring the organization ID, user ID,
 * and the add-ons to be added. Cancelation is not applicable for update flows.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription update process.
 * - `cancelUrl`: Must not be provided in update flows as cancelation is not applicable.
 * - `newAddons`: `AddonItem` objects representing additional feature or services to add to the subscription.
 * - `returnUrl`: This field is not applicable in the update flow and is excluded.
 */
export interface HandlingUpdateSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  userId: string;
  newAddon: AddonItem;
}

/**
 * Extends `HandlingSubscriptionFlow` for viewing a subscription flow.
 *
 * This structure is used when displaying subscription details or confirmation pages. The `returnUrl`
 * field becomes mandatory to define the redirection after completing the viewing process.
 *
 * - `returnUrl`: The URL to return to after completing the subscription view process.
 * - Other fields (`organizationId`, `userId`, `successUrl`, `cancelUrl`) are not applicable for viewing flows.
 */
export interface HandlingViewSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl"> {
  returnUrl: string;
}

/**
 * Represents the data structure required to handle a subscription cancellation flow.
 *
 * This interface extends the base `HandlingSubscriptionFlow` but excludes certain optional fields
 * (`organizationId`, `userId`, `successUrl`, `cancelUrl`, and `projectId`) and redefines them as mandatory.
 *
 * ## Fields
 * - `successUrl` (string): The URL to redirect the user to after successfully canceling the subscription.
 * - `cancelUrl` (string): The URL to redirect the user to if the subscription cancellation is aborted or fails.
 * - `organizationId` (string): The unique identifier of the organization associated with the subscription to be canceled.
 * - `userId` (string): The unique identifier of the user initiating the cancellation process.
 * - `projectId` (string): The unique identifier of the project to be removed after subscription is being canceled.
 *
 * ## Usage
 * This interface ensures all required parameters for the cancellation flow
 * are provided and is primarily used in subscription cancellation API routes.
 */
export interface HandlingCancelSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl" | "projectId"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  userId: string;
  projectId: string;
}

/**
 * Represents the successful response of a subscription creation process.
 *
 * This interface defines the structure of the response returned when a subscription
 * is successfully created and a Stripe Checkout session is generated.
 *
 * ## Properties:
 * - `stripeSession`: The Stripe Checkout session object that includes detailed
 *   information about the created session, such as its ID, status, and URLs.
 * - `redirectUrl`: A URL to redirect the user to complete the payment process
 *   in the Stripe Checkout interface.
 *
 */
export interface CreateSubscriptionSuccessResponse {
  stripeSession: Stripe.Response<Stripe.Checkout.Session>;
  redirectUrl: Stripe.Checkout.Session["url"];
}

/**
 * Represents the response from a successful subscription update operation.
 *
 * - `status`: Indicates the current state of the subscription update process.
 *   - `"completed"`: The subscription upgrade was successfully completed with a successful payment.
 *   - `"pending"`: The subscription update process is incomplete, and an invoice needs to be paid.
 *     In this state, the `redirectUrl` will point to the Stripe-hosted invoice page for payment.
 * - `redirectUrl`: The URL to redirect the user based on the status:
 *   - If `status` is `"completed"`, this could be a confirmation page or the application's subscription management page.
 *   - If `status` is `"pending"`, this will be the Stripe-hosted invoice page for the user to complete the payment.
 */
export interface UpdateSubscriptionSuccessResponse {
  status: "completed" | "pending";
  redirectUrl: string;
}

/**
 * Represents the response returned upon successful deletion of a subscription.
 *
 * ## Fields
 * - `redirectUrl` (string): The URL to redirect the user to after the subscription has been successfully canceled.
 *
 * ## Usage
 * This interface is used to define the structure of the success response for API routes
 * handling subscription cancellations. It provides a redirection path for the user
 * to confirm the success of the operation or guide them to the next action.
 */
export interface CancelSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Represents the response for successfully handling a view subscription flow.
 *
 * - `redirectUrl`: The URL to which the user should be redirected after
 *   successfully completing the view subscription process.
 *
 */
export interface ViewSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Defines a line item used in Stripe Checkout sessions.
 *
 * - `price`: The ID of the price object in Stripe.
 * - `quantity`: The quantity of the item being purchased.
 *
 * @example
 * const lineItem: LineItem = {
 *   price: 'price_12345',
 *   quantity: 2,
 * };
 */
export type LineItem = Stripe.Checkout.SessionCreateParams.LineItem;

/**
 * Represents the possible states in the lifecycle of a subscription-related operation.
 *
 * ## States
 * - `draft`: The operation is in its initial state, with no associated Stripe invoice.
 *   This indicates the operation has been created but not yet moved to billing or further processing.
 * - `pending`: The operation has an associated Stripe invoice that is not yet paid.
 *   This indicates that the operation is awaiting payment before further action can occur.
 * - `ready`: The operation is fully prepared for processing.
 *   This indicates that the Stripe invoice is finalized and paid, and the operation can proceed.
 * - `processed`: The operation has been successfully processed by the backend.
 *   This state indicates that all associated actions have been completed or are in progress.
 * - `cancelled`: The operation has been explicitly cancelled and will not proceed further.
 *   This can occur due to user action, subscription cancellation, or other conditions.
 *
 * ## Usage
 * The `OperationStatus` type provides a standardized framework for tracking the lifecycle of
 * subscription-related operations. It ensures clear workflows for managing and processing
 * operations in both frontend and backend systems.
 */
export type OperationStatus =
  | "draft"
  | "pending"
  | "ready"
  | "processed"
  | "cancelled";

/**
 * Represents the status and details of subscription-related operations for an organization.
 *
 * An operation describes the lifecycle of a subscription-related action, such as upgrading a plan,
 * adding an add-on, or creating a resource. Depending on the type, operations may or may not be
 * tied to Stripe invoices (`free` operations do not involve invoices).
 *
 * The operation status is derived from the operation fields using this method: getOperationState(operation: Operation)
 *
 * ## Structure
 * - `user_id`: The unique identifier of the user who initiated the operation.
 * - `created_at`: The timestamp when the operation was created.
 * - `updated_at`: The timestamp when the operation was last updated.
 * - `invoice_id` (Optional): The Stripe invoice ID associated with the operation, if applicable.
 * - `invoice_finalized` (Optional): The finalized Stripe invoice linked to the operation, if applicable.
 * - `invoice_paid` (Optional): The paid Stripe invoice linked to the operation, if applicable.
 * - `processed_at` (Optional): The timestamp indicating when the operation was marked as processed.
 * - `cancelled_at` (Optional): The timestamp indicating when the operation was marked as cancelled.
 * - `addonItem`: Details about the add-ons included in the operation, specifying the type and actions.
 */
export type Operation = {
  user_id: string;
  created_at: string;
  updated_at: string;
  invoice_id?: string;
  invoice_finalized?: Stripe.Invoice;
  invoice_paid?: Stripe.Invoice;
  processed_at?: string;
  cancelled_at?: string;
  addonItem: AddonItem;
};

/**
 * Represents a collection of subscription-related operations, indexed by their unique operation IDs (`op_id`).
 *
 * Each operation corresponds to a specific action within the subscription system, such as a plan upgrade
 * or the addition of an add-on. The operations progress through defined statuses (`OperationStatus`),
 * providing a clear workflow for handling and processing subscription-related tasks.
 */
export interface Operations {
  [op_id: string]: Operation;
}

/**
 * The props for the `SubscriptionStatusBanner` and `SubscriptionStatusAlertDialog` component.
 *
 * @property {string} title - The title.
 * @property {string} description - The description displayed below the title.
 * @property {string} actionButtonText - The text for the action button on the alert.
 */
export type SubscriptionStatusAlertProps = {
  title: string;
  description: string;
  actionButtonText: string;
};

/**
 * Maps each Stripe event type to its corresponding `event.data.object` type.
 *
 * This type is used to associate specific Stripe event types with their expected resource objects,
 * ensuring proper type inference and validation when handling these events.
 *
 * ### Keys:
 * - Stripe event types that the application handles (e.g., `"invoice.finalized"`, `"customer.subscription.updated"`).
 *
 * ### Values:
 * - The type of the `event.data.object` for the corresponding Stripe event (e.g., `Stripe.Invoice`, `Stripe.Subscription`).
 */
export type StripeEventDataMap = {
  "invoice.finalized": Stripe.Invoice;
  "invoice.paid": Stripe.Invoice;
  "customer.subscription.created": Stripe.Subscription;
  "customer.subscription.updated": Stripe.Subscription;
  "customer.subscription.deleted": Stripe.Subscription;
  "customer.created": Stripe.Customer;
  "customer.updated": Stripe.Customer;
  "invoice.created": Stripe.Invoice;
  "invoice.updated": Stripe.Invoice;
  "invoice.deleted": Stripe.Invoice;
};

/**
 * Extracts the subset of Stripe event types handled by the application.
 *
 * This type represents the keys of the `StripeEventDataMap`, restricting the event types to only
 * those explicitly defined as being handled by the application.
 */
export type StripeEventSubset = keyof StripeEventDataMap;

/**
 * Extends the `Stripe.Event` type to create a discriminated union for handled events.
 *
 * This type maps each Stripe event type to a more specific structure, including its exact `type`
 * and the expected shape of the `event.data.object`. It ensures precise type inference for each
 * handled Stripe event, reducing ambiguity and improving code reliability.
 *
 * ### Structure:
 * - `type`: The specific Stripe event type (e.g., `"invoice.finalized"`, `"customer.subscription.updated"`).
 * - `data.object`: The associated resource object for the event (e.g., `Stripe.Invoice`, `Stripe.Subscription`).
 *
 * ### Usage:
 * This type is used to define the shape of events being processed by the application, enabling
 * type-safe handling of Stripe webhooks.
 */
export type HandledStripeEvent = {
  [K in StripeEventSubset]: Omit<Stripe.Event, "type" | "data"> & {
    type: K; // Specific event type.
    data: {
      object: StripeEventDataMap[K]; // Associated resource type.
    };
  };
}[StripeEventSubset];
