//
// index.tsx - API requests and mutations related functionality
//

import { BackendSuccessResponse } from "@data-types/backend-response-types";
import {
  EditDataOpt,
  EditDataResult,
  EditDataReturn,
} from "@data-types/generic-hook-type";
import {
  ClientError,
  FetchApiOptions_v2,
  fetchApiRoute_v2,
} from "@lib/client-side";
import { ErrorDialog } from "@tw-components/error/ErrorDialog";
import { ErrorMessage } from "@tw-components/error/ErrorMessage";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useSWRConfig } from "swr";

/**
 * Custom hook to handle API calls and data mutations with state management.
 *
 * @template T - The type of the edited data result.
 * @param opt - Configuration options for callbacks and global error handling.
 * @returns An object containing state variables and the `editData` function to perform API calls.
 */
export function useEditData_v2<T>(opt: EditDataOpt<T>): EditDataReturn<T> {
  const {
    startCallback = null,
    errorCallback = null,
    successCallback = null,
    mutatedCallback = null,
    stopGlobalError = false,
    customErrorDialog = null,
  } = opt;

  const { mutate } = useSWRConfig();

  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InstanceType<typeof ClientError> | null>(
    null
  );
  const [editedData, setEditedData] =
    useState<BackendSuccessResponse<T> | null>(null);
  const [mutatedData, setMutatedData] = useState<any>(null);
  const [isMutatingData, setIsMutatingData] = useState(false);

  // Automatically render the error portal when an error occurs
  useEffect(() => {
    if (!stopGlobalError) {
      const portalContainer =
        document.getElementById("error-portal") ??
        (() => {
          const div = document.createElement("div");
          div.id = "error-portal";
          document.body.appendChild(div);
          return div;
        })();

      const root = createRoot(portalContainer);

      if (error) {
        if (customErrorDialog) {
          root.render(<ErrorDialog {...customErrorDialog} />);
        } else {
          root.render(
            <ErrorMessage message={error.message} details={error.details} />
          );
        }
      } else {
        root.unmount();
      }

      return () => root.unmount();
    }
  }, [error]);

  // Resets all states before a new operation
  const resetStates = () => {
    setEditedData(null);
    setMutatedData(null);
    setError(null);
  };

  /**
   * Handles API mutations when mutate targets are provided.
   *
   * @param mutateApis - Array of API endpoints to mutate.
   * @returns Array of mutated results.
   */
  const handleMutations = async (mutateApis: string[][]) => {
    const results: any[] = [];
    for (const api of mutateApis) {
      const result = await mutate(api);
      results.push(result);
    }
    return results;
  };

  /**
   * Performs the API call and manages related state.
   *
   * @param fetchOpt - Options for the API call, including the endpoint, method, body, and mutation targets.
   */
  const editData = async (
    fetchOpt: FetchApiOptions_v2 & {
      mutateApis?: string[][];
    }
  ): Promise<EditDataResult<T>> => {
    resetStates();

    // Invoke start callback if provided
    await startCallback?.();

    const { mutateApis } = fetchOpt;
    let errorCaught = false;
    setIsLoading(true);

    // Indicate mutating state if targets are provided
    if (mutateApis?.length) {
      setIsMutatingData(true);
    }

    try {
      // Perform the API request
      const data = await fetchApiRoute_v2<T>(fetchOpt);

      // Invoke the edited callback if provided
      successCallback?.(data);
      setEditedData(data);

      // Return the successful data
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      // Handle errors and invoke the error callback
      setError(error);
      errorCallback?.(error);
      errorCaught = true;

      // Return error
      return {
        success: false,
        error,
      };
    } finally {
      setIsLoading(false);
      // Handle mutations if targets are provided
      if (mutateApis?.length && !errorCaught) {
        const mutationResults = await handleMutations(mutateApis);
        setMutatedData(mutationResults);
        mutatedCallback?.();
      }

      setIsMutatingData(false);
    }
  };

  return {
    isLoading,
    error: error,
    editedData,
    mutatedData,
    isMutatingData,
    editData,
  };
}
