import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt } from "@data-types/generic-hook-type";

/**
 * Custom hook for handling project deletion logic.
 *
 * @param onSuccess - Callback to execute upon successful project deletion.
 * @param onError - Callback to execute upon error during project deletion.
 *
 * @returns Hook state and actions for managing project deletion.
 */
export function useDeleteProject(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData } = useEditData_v2<null>({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const deleteProject = async (projectId: string) => {
    return await editData({
      method: "GET",
      endpoint: `/api/projects/${projectId}/delete`,
      endpointCallLocation: "useDeleteProject",
      mutateApis: [
        ["/api/projects", "useGetUserProjects"],
        ["/api/projects", "useGetProjects"],
        ["/api/auth/session", "useGetUserSession"],
      ],
    });
  };

  return {
    deleteProject,
    isDeletingProject: isLoading || isMutatingData,
  };
}
