//
// useCancellSubscription.ts
//

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  CancelSubscriptionSuccessResponse,
  HandlingCancelSubscriptionFlow,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { useCallback } from "react";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to manage the subscription cancellation process.
 *
 * @param {EditDataOpt<CancelSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `cancelSubscription` (function): A function to initiate the subscription cancellation process.
 *       - Parameters:
 *         - `projectId` (string): The ID of the project to delete when subscription is cancelled.
 *       - Returns:
 *         - `Promise<CancelSubscriptionSuccessResponse>`: The result of the subscription cancellation process.
 *   - `isLoading` (boolean): Indicates whether the cancellation process or associated mutation is in progress.
 */
export const useCancelSubscription = (
  options: EditDataOpt<CancelSubscriptionSuccessResponse>
) => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const projects = availableSession
    ? Object.entries(session?.authorizations?.projects ?? [])
    : [];

  const { email = "", userId = "" } = {
    userId: session?.userId,
    email:
      organizationId &&
      session?.authorizations?.organizations[organizationId]?.ownerEmail,
  };

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<CancelSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const cancelSubscription = useCallback(
    async (projectId: string) => {
      const cancelSubscriptionResponse = await editData({
        method: "DELETE",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useCancelSubscription.js",
        body: {
          successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
          cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
          organizationId,
          email,
          userId,
          projectId,
        } as HandlingCancelSubscriptionFlow,
        mutateApis: [],
      });

      return cancelSubscriptionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    cancelSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
