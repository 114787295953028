import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  CreateSubscriptionSuccessResponse,
  HandlingCreateSubscriptionFlow,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to manage the subscription creation process.
 *
 * @param {EditDataOpt<CreateSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `createSubscription` (function): A function to initiate the subscription creation process.
 *       - Parameters:
 *         - `newAddon` (AddonItem<AddonLookupKey>): The add-on item to add to the subscription.
 *       - Returns:
 *         - `Promise<CreateSubscriptionSuccessResponse>`: The result of the subscription creation process.
 *   - `isLoading` (boolean): Indicates whether the subscription creation or associated mutation is in progress.
 */
export const useCreateSubscription = (
  options: EditDataOpt<CreateSubscriptionSuccessResponse>
) => {
  const posthog = usePostHog();
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const projects = availableSession
    ? Object.entries(session?.authorizations?.projects ?? [])
    : [];

  const {
    email = "",
    userId = "",
    projectId = "",
  } = {
    userId: session?.userId,
    email:
      organizationId &&
      session?.authorizations?.organizations[organizationId]?.ownerEmail,
    projectId: projects.length ? projects[0][0] : undefined,
  };

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<CreateSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
      customErrorDialog: {
        title: "Failed to upgrade",
        message:
          "Something went wrong while upgrading. Please try again later. If the issue persists, contact us for assistance.",
      },
    });

  const createSubscription = useCallback(
    async ({ newAddon }: Pick<HandlingCreateSubscriptionFlow, "newAddon">) => {
      posthog.capture("subscribe:notify_pro");

      const createSessionResponse = await editData({
        method: "POST",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useCreateSubscription.js",
        body: {
          newAddon,
          successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
          cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
          organizationId,
          email,
          userId,
          projectId,
        } as HandlingCreateSubscriptionFlow,
        mutateApis: [],
      });

      return createSessionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    createSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
