import { useGetCurrentOrganizationId } from "@custom-hooks/billing";
import { useGroupProjectsByLookupKey } from "@custom-hooks/billing/hooks/useGroupProjectsByLookupKey";
import { useGetUserSession } from "@custom-hooks/user";
import {
  AddonActionNames,
  AddonItem,
  AddonLookupKey,
} from "@data-types/billing-types";
import { Environment } from "@data-types/projects-types";
import { Region } from "@data-types/regions-types";
import { getOperationState } from "@lib/billing/iso-utils";
import { useEffect, useMemo, useState } from "react";
import { useGetUserProjects } from "./useGetUserProjects";

export const useGetAllUserProjects = () => {
  const [projectsRefreshInterval, setProjectsRefreshInterval] = useState<
    number | undefined
  >(undefined);

  const groupedProjects = useGroupProjectsByLookupKey();
  const sandboxProjectsQuantity =
    groupedProjects?.sandbox_project.quantity ?? 0;

  const { data: session } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();
  const {
    data: projects,
    hasData: availableProjects,
    isLoading: loadingProjects,
    emptyData: emptyProjects,
  } = useGetUserProjects(true, projectsRefreshInterval);

  const operations = organizationId
    ? session?.authorizations?.organizations[organizationId]?.attributes
        ?.subscription_operations
    : undefined;

  const { actualProjectCount, projectsToBeCreated, projectsToBeDeleted } =
    useMemo(() => {
      const projectsToBeCreated: {
        name: string;
        region: Region["region_id"];
        env: Environment;
        description?: string;
      }[] = [];
      const projectsToBeDeleted: string[] = [];

      if (!operations) {
        return { projectsToBeCreated, projectsToBeDeleted };
      }

      const readyProjectAddOnItems: AddonItem[] = [];
      const processedProjectAddOnItems: AddonItem[] = [];
      let actualProjectCount = 0;

      Object.values(operations).forEach((operation) => {
        if (operation?.addonItem?.addon === AddonLookupKey.PRO_PROJECT) {
          const operationStatus = getOperationState(operation);
          switch (operationStatus) {
            case "ready":
              readyProjectAddOnItems.push(operation?.addonItem);
              break;
            case "processed":
              processedProjectAddOnItems.push(operation?.addonItem);
              break;
          }
        }
      });

      readyProjectAddOnItems.forEach((addOnItem) => {
        switch (addOnItem?.action.name) {
          case AddonActionNames.CREATE:
            actualProjectCount += addOnItem.quantity;

            if (addOnItem.action.data) {
              projectsToBeCreated.push(addOnItem.action.data);
            }
            break;
          case AddonActionNames.DELETE:
            actualProjectCount += addOnItem.quantity;
            projectsToBeDeleted.push(addOnItem.action.data.projectId);
            break;

          case AddonActionNames.UNSUBSCRIBE:
            actualProjectCount -= 1;
            projectsToBeDeleted.push(addOnItem.action.data.projectId);
            break;
        }
      });

      processedProjectAddOnItems.forEach((addOnItem) => {
        switch (addOnItem?.action.name) {
          case AddonActionNames.CREATE:
          case AddonActionNames.DELETE:
            actualProjectCount += addOnItem.quantity;
            break;
          case AddonActionNames.UNSUBSCRIBE:
            actualProjectCount -= 1;

            break;
        }
      });

      return { actualProjectCount, projectsToBeCreated, projectsToBeDeleted };
    }, [operations, projects]);

  useEffect(() => {
    console.log("POLLING");
    console.log("actualProjectCount: " + actualProjectCount);
    if (projects?.length && actualProjectCount) {
      if (actualProjectCount + sandboxProjectsQuantity === projects.length) {
        if (projectsRefreshInterval !== undefined) {
          setProjectsRefreshInterval(undefined);
        }
      } else {
        setProjectsRefreshInterval(1000);
      }
    }
  }, [projectsToBeCreated, projectsToBeDeleted, actualProjectCount]);

  return {
    projects,
    availableProjects,
    loadingProjects,
    emptyProjects,
    projectsToBeCreated,
    projectsToBeDeleted,
  };
};
