//
// iso-utils.ts
//

import { Operation, OperationStatus } from "@data-types/billing-types";

/**
 * Determines if an operation is "draft".
 *
 * A "draft" operation is defined as one where:
 * - `invoice_id`, `invoice_finalized`, and `invoice_paid` are all null or undefined.
 *
 * @param operation - The operation to evaluate.
 * @returns {boolean} True if the operation is draft, otherwise false.
 */
export function isOperationDraft(operation: Operation): boolean {
  // Destructure fields for readability
  const { invoice_id, invoice_finalized, invoice_paid } = operation;

  // Check if all fields are null or undefined
  return !invoice_id && !invoice_finalized && !invoice_paid;
}

/**
 * Determines if an operation is "pending".
 *
 * A "pending" operation is defined as one where:
 * - `invoice_id` and `invoice_finalized` are defined.
 * - `invoice_paid` is null or undefined.
 * - The `invoice_id` matches the `id` field in `invoice_finalized`.
 *
 * @param operation - The operation to evaluate.
 * @returns {boolean} True if the operation is pending, otherwise false.
 */
export function isOperationPending(operation: Operation): boolean {
  // Destructure fields for readability
  const { invoice_id, invoice_finalized, invoice_paid } = operation;

  // Check if `invoice_id` and `invoice_finalized` are defined, and `invoice_paid` is not
  if (!invoice_id || !invoice_finalized || invoice_paid) {
    return false;
  }

  // Verify that the `invoice_id` matches the `id` field in `invoice_finalized`
  const isConsistent = invoice_finalized.id === invoice_id;

  return isConsistent;
}

/**
 * Determines if an operation is "ready".
 *
 * A "ready" operation is defined as one where:
 * - `invoice_id`, `invoice_finalized`, and `invoice_paid` are all defined.
 * - The `invoice_id` matches the `id` field in both `invoice_finalized` and `invoice_paid`.
 *
 * @param operation - The operation to evaluate.
 * @returns {boolean} True if the operation is ready, otherwise false.
 */
export function isOperationReady(operation: Operation): boolean {
  // Destructure fields for readability
  const { invoice_id, invoice_finalized, invoice_paid } = operation;

  // Ensure all required fields are defined
  if (!invoice_id || !invoice_finalized || !invoice_paid) {
    return false;
  }

  // Check if the `invoice_id` matches in `invoice_finalized` and `invoice_paid`
  const isConsistent =
    invoice_finalized.id === invoice_id && invoice_paid.id === invoice_id;

  return isConsistent;
}

/**
 * Determines if an operation has been processed.
 *
 * An operation is considered "processed" if the `processed_at` field is defined,
 * indicating that the operation's actions have been completed or are in progress.
 *
 * @param operation - The operation to check.
 * @returns `true` if the operation has been processed, `false` otherwise.
 */
export function isOperationProcessed(operation: Operation): boolean {
  // Check if the `processed_at` field is defined
  return !!operation.processed_at;
}

/**
 * Determines if an operation has been cancelled.
 *
 * An operation is considered "cancelled" if the `cancelled_at` field is defined,
 * indicating that the operation has been explicitly marked as cancelled.
 *
 * @param operation - The operation to check.
 * @returns `true` if the operation has been cancelled, `false` otherwise.
 */
export function isOperationCancelled(operation: Operation): boolean {
  // Check if the `cancelled_at` field is defined
  return !!operation.cancelled_at;
}

/**
 * Determines the current state of an operation based on its actual properties.
 *
 * The state is determined in the following priority:
 * 1. `processed`: If the operation has been processed.
 * 2. `cancelled`: If the operation has been cancelled.
 * 3. `ready`: If the operation is ready for processing.
 * 4. `pending`: If the operation is awaiting payment.
 * 5. `draft`: If the operation is in its initial state with no associated invoices.
 *
 * @param operation - The operation to evaluate.
 * @returns {OperationStatus} The current state of the operation.
 */
export function getOperationState(operation: Operation): OperationStatus {
  // Check if the operation has been processed
  if (isOperationProcessed(operation)) {
    return "processed";
  }

  // Check if the operation has been cancelled
  if (isOperationCancelled(operation)) {
    return "cancelled";
  }

  // Check if the operation is ready for processing
  if (isOperationReady(operation)) {
    return "ready";
  }

  // Check if the operation is pending
  if (isOperationPending(operation)) {
    return "pending";
  }

  // If none of the above, the operation is in the draft state
  if (isOperationDraft(operation)) {
    return "draft";
  }

  // If no valid state is determined, throw an error
  // TODO:TIZ:Use the iso throw error that i have to develop
  throw new Error(
    "Unable to determine the operation state. Invalid operation properties."
  );
}
