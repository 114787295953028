import { ContactDialog } from "@tw-components/OrgHome/ContactDialog";
import { MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useState } from "react";

export type ErrorDialogProps = {
  title: string;
  message: string;
};

export function ErrorDialog({ title, message }: ErrorDialogProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const [showContactDialog, setShowContactDialog] = useState(false);

  return (
    <>
      <GenericDialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title={title}
        submitButtonProps={{
          variant: "primary",
          label: "Close",
          className: "tw-w-52",
          onClick: () => {
            setIsOpen(false);
          },
        }}
        secondaryButtonProps={{
          text: "Contact us",
          onClick: () => {
            setIsOpen(false);
            setShowContactDialog(true);
          },
        }}
        alertMessage={{ type: MessageType.Error, title: message }}
        size="2xl"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
      />
    </>
  );
}
