//
// UpgradeDialog.tsx - Upgrade Dialog Component
//

import {
  useCreateSubscription,
  useGetSubscriptionDetails,
  useUpdateSubscription,
  useViewSubscription,
} from "@custom-hooks/billing";
import { useGetCurrentProjectId } from "@custom-hooks/projects";
import {
  AddonActionNames,
  AddonItem,
  AddonLookupKey,
  AddonsFunctionality,
} from "@data-types/billing-types";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import {
  addons,
  FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE,
  PRO_PROJECT_PRICE,
} from "@lib/billing/constants";
import { ContactDialog } from "@tw-components/OrgHome/ContactDialog";
import { Message } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useRouter } from "next/router";
import { useState } from "react";
import { SubscriptionStatusDialog } from "../SubscriptionStatusDialog";
import { PlanCard, PlanCardProps } from "./PlanCard";

/**
 * The props for the UpgradeDialog.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} projectId - The project to upgrade.
 */
type UpgradeDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
};

/**
 * Component for displaying
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 *
 * @returns {JSX.Element} The rendered `UpgradeDialog` component.
 */
export function UpgradeDialog({
  isOpen,
  onClose,
  projectId,
}: UpgradeDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message | undefined>(
    undefined
  );
  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] =
    useState(false);

  const rowNames = [
    AddonsFunctionality.CLUSTER,
    AddonsFunctionality.STORAGE_IN_GB,
    `${AddonsFunctionality.VCPU} / ${AddonsFunctionality.RAM}`,
    AddonsFunctionality.RETENTION_IN_HOURS,
    AddonsFunctionality.SUPPORT,
    "Cost",
  ];

  const currentPlanDetails =
    addons[AddonLookupKey.SANDBOX_PROJECT].functionalityDetails;
  const proPlanDetails =
    addons[AddonLookupKey.PRO_PROJECT].functionalityDetails;

  const currentPlanCardProps: PlanCardProps = {
    title: "Free Project",
    details: [
      { ...currentPlanDetails.Cluster },
      { ...currentPlanDetails.Storage },
      {
        summary: `${currentPlanDetails.vCPUs?.summary} / ${currentPlanDetails.RAM?.summary}`,
      },
      { summary: "None" },
      { ...currentPlanDetails.Support },
      { summary: "Free" },
    ],
  };

  const proPlanCardProps: PlanCardProps = {
    title: "Pro Project",
    details: [
      { ...proPlanDetails.Cluster },
      { ...proPlanDetails.Storage },
      {
        summary: `${proPlanDetails.vCPUs?.summary} / ${proPlanDetails.RAM?.summary}`,
      },
      { ...proPlanDetails.Backups },
      { ...proPlanDetails.Support },
      { summary: `$${PRO_PROJECT_PRICE} / month / project` },
    ],
  };

  const currentProjectId = useGetCurrentProjectId();
  const router = useRouter();

  const {
    subscriptionStatus,
    hasSubscription,
    canProceedWithOperation,
    subscriptionStatusAlertProps,
  } = useGetSubscriptionDetails();

  const { updateSubscription, isLoading: isLoadingUpdateSubscription } =
    useUpdateSubscription({
      successCallback: () => {
        onClose();

        if (currentProjectId) {
          router.push("/");
        }
      },
      errorCallback: () => {
        setAlertMessage(FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE);
      },
    });
  const { createSubscription, isLoading: isLoadingCreateSubscription } =
    useCreateSubscription({
      successCallback: () => {
        onClose();
      },
      errorCallback: () => {
        setAlertMessage(FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE);
      },
    });
  const { viewSubscription, isLoading: isLoadingViewSubscription } =
    useViewSubscription({
      errorCallback: () => {
        setAlertMessage(FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE);
      },
      stopGlobalError: true,
    });

  const handleUpgrade = async () => {
    if (subscriptionStatus === "incomplete") {
      await viewSubscription();
    } else {
      const newAddon: AddonItem = {
        addon: AddonLookupKey.PRO_PROJECT,
        quantity: 1,
        action: {
          name: AddonActionNames.UPGRADE,
          data: {
            projectId,
          },
        },
      };

      if (hasSubscription) {
        if (!canProceedWithOperation) {
          setShowSubscriptionStatusDialog(true);
        } else {
          await updateSubscription({
            newAddon: newAddon,
          });
        }
      } else {
        await createSubscription({
          newAddon: newAddon,
        });
      }
    }
  };

  return (
    <>
      <GenericDialog
        title="Upgrade to SQLite Cloud Pro"
        description={
          "Upgrading to SQLite Cloud Pro will automatically turn your existing free project into a pro project."
        }
        body={
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-row">
              <div className="tw-flex tw-flex-col tw-justify-end tw-gap-5 tw-pb-4 tw-pr-2 sm:tw-min-w-28 sm:tw-pr-8">
                {rowNames.map((rowName) => (
                  <div
                    key={rowName}
                    className="tw-text-12px-semiBold tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark"
                  >
                    {rowName}
                  </div>
                ))}
              </div>

              <div className="tw-grid tw-w-full tw-grid-cols-2 tw-gap-4">
                <PlanCard {...currentPlanCardProps} />
                <PlanCard
                  {...proPlanCardProps}
                  classname="tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark"
                />
              </div>
            </div>
          </div>
        }
        alertMessage={alertMessage}
        secondaryButtonProps={{
          text: "Need more? Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        submitButtonProps={{
          variant: "primary",
          label: "Upgrade to Pro",
          icon: <BoltIcon />,
          onClick: handleUpgrade,
          className: "tw-w-[22.188rem]",
          showLoader:
            isLoadingCreateSubscription ||
            isLoadingViewSubscription ||
            isLoadingUpdateSubscription,
        }}
        onClose={onClose}
        open={isOpen}
        size="xl"
        buttonLayoutType="column"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
      />

      {subscriptionStatusAlertProps && (
        <SubscriptionStatusDialog
          {...subscriptionStatusAlertProps}
          isOpen={showSubscriptionStatusDialog}
          onClose={() => setShowSubscriptionStatusDialog(false)}
        />
      )}
    </>
  );
}
