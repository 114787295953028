//
// useGroupProjectsByLookupKey.ts
//

import { useGetUserSession } from "@custom-hooks/user";
import { AddonLookupKey } from "@data-types/billing-types";
import { useMemo } from "react";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * React hook to group projects by `plan_id` and map them to their corresponding `AddonLookupKey`.
 *
 * This hook fetches the current user's session, retrieves the organization ID, and filters
 * projects belonging to that organization. It then groups the projects by their `plan_id`
 * (e.g., "sandbox", "pro"), maps them to predefined `AddonLookupKey` values, and returns a summary.
 *
 * Additionally, it includes a `paid_projects` key that aggregates all non-sandbox projects.
 *
 * @returns {Record<AddonLookupKey | "paid_projects", { quantity: number; projects: string[] }> | null}
 * A map where keys are `AddonLookupKey` values or `paid_projects`, and each value contains:
 * - `quantity`: The number of projects in the group.
 * - `projects`: An array of project IDs in the group.
 * Returns `null` if session data or organization ID is unavailable.
 */
export function useGroupProjectsByLookupKey(): Record<
  AddonLookupKey | "paid_projects",
  { quantity: number; projects: string[] }
> | null {
  // Get user session and its status using a custom hook
  const { hasData, data: session } = useGetUserSession();

  // Get the current organization ID
  const organizationId = useGetCurrentOrganizationId();

  return useMemo(() => {
    // Check if session data or organization ID is unavailable
    if (!hasData || !session || !organizationId) {
      console.warn("User session or organization ID is unavailable.");
      return null;
    }

    const projects = session.authorizations?.projects;

    // Check if projects exist in session data
    if (!projects) {
      console.warn("Projects not found in user authorizations.");
      return null;
    }

    // Filter projects belonging to the specified organization ID
    const filteredProjects = Object.entries(projects).filter(
      ([_, project]) => project.organization_id === organizationId
    );

    // Initialize the result map with all possible AddonLookupKey values
    const groupedProjects: Record<
      AddonLookupKey | "paid_projects",
      { quantity: number; projects: string[] }
    > = {
      [AddonLookupKey.SANDBOX_PROJECT]: { quantity: 0, projects: [] },
      [AddonLookupKey.PRO_PROJECT]: { quantity: 0, projects: [] },
      [AddonLookupKey.SCALE_PROJECT]: { quantity: 0, projects: [] },
      paid_projects: { quantity: 0, projects: [] },
    };

    // Iterate over the filtered projects and group them by plan_id
    for (const [projectId, project] of filteredProjects) {
      // Extract the plan_id or default to "sandbox" if undefined
      const planId =
        (project.attributes as unknown as Record<string, string>).plan_id ||
        AddonLookupKey.SANDBOX_PROJECT;

      // Map the plan ID to the corresponding AddonLookupKey
      let projectLookupKey = planId as AddonLookupKey;
      switch (planId) {
        case "sandbox": {
          projectLookupKey = AddonLookupKey.SANDBOX_PROJECT;
          break;
        }
        case "pro": {
          projectLookupKey = AddonLookupKey.PRO_PROJECT;
          break;
        }
      }

      // Add the project to the appropriate group
      console.log("projectLookupKey: " + projectLookupKey); //TOGLI
      groupedProjects[projectLookupKey].quantity += 1;
      groupedProjects[projectLookupKey].projects.push(projectId);

      // If the project is not sandbox, add it to `paid_projects`
      if (projectLookupKey !== AddonLookupKey.SANDBOX_PROJECT) {
        groupedProjects.paid_projects.quantity += 1;
        groupedProjects.paid_projects.projects.push(projectId);
      }
    }

    return groupedProjects;
  }, [hasData, organizationId, session]);
}
